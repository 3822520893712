import { TrialView } from '../../../../typescript/API'
import {
  Box,
  Chip,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  abbrev_genes,
  abbrev_therapies,
  uniq_disp,
} from '../../Functions_Calculate'
import { drugChip, tmcRender } from '../TrialInfoElements'
import React, { useState } from 'react'
import { Place } from '@mui/icons-material'
import TrialSitePopover from './TrialSitePopover'

function TrialCard({ trial, most }: { trial: TrialView; most: boolean }) {
  const [anchorEl, setAnchorEl] = useState<any>(null)

  const display_tier: string[] = trial.display_tier as string[]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 1,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', width: 1 }}>
        <Box sx={{ flex: 1, margin: 1 / 3 }}>
          <Typography
            variant="body2"
            gutterBottom
          >
            {uniq_disp(trial.uniq_id)}
          </Typography>
        </Box>
        <Box sx={{ flex: 3, margin: 1 / 3 }}>
          <Chip
            sx={{
              '& .MuiChip-icon': { mx: 0 },
              '& .MuiChip-label': { pl: most ? 1 / 2 : undefined },
            }}
            variant={'outlined'}
            label={
              <Link
                href={trial.ext_weblink}
                target="_blank"
              >
                {trial.first_col[0]}
              </Link>
            }
            icon={
              most ?
                <IconButton
                  size={'small'}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    e.stopPropagation()
                  }}
                >
                  <Place fontSize={'small'} />
                </IconButton>
              : undefined
            }
          />
          <TrialSitePopover
            trialID={trial.first_col[0]}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </Box>
        <Box sx={{ flex: 8, margin: 1 / 3 }}>
          {trial.first_col[1] && trial.first_col[1].length > 22 ?
            <Tooltip
              title={
                <Typography style={{ fontSize: 14 }}>
                  {trial.first_col[1]}
                </Typography>
              }
            >
              <Typography variant="body2">
                {trial.first_col[1] !== null &&
                  trial.first_col[1].substring(0, 25 - 3) + '...'}
              </Typography>
            </Tooltip>
          : <Typography variant="body2">
              {trial.first_col[1] !== null && trial.first_col[1]}
            </Typography>
          }
        </Box>
        <Box sx={{ flex: 1, margin: 1 / 3 }}>
          <Typography variant="body2">{display_tier.join('/')}</Typography>
        </Box>
        <Box sx={{ flex: 1, margin: 1 / 3 }}>{tmcRender(trial)}</Box>
        <Box sx={{ flex: 3, margin: 1 / 3 }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold' }}
            gutterBottom
          >
            {abbrev_genes(trial.genes)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100%' }}>
        <Box
          sx={{
            flex: 3,
            flexDirection: 'column',
            alignItems: 'center',
            margin: 1 / 3,
          }}
        >
          {trial.first_col[2]
            .split(';')
            .map((drug) => drugChip(abbrev_therapies(drug)))}
        </Box>
      </Box>
      <Stack
        gap={1 / 2}
        sx={{ width: '100%', textAlign: 'left', padding: 1 / 2 }}
      >
        <Typography
          variant="body2"
          fontWeight={'bold'}
        >
          {`${trial.short_title} (${trial.category})`}
        </Typography>
        <Typography variant="body2">{trial.title}</Typography>
      </Stack>
    </Box>
  )
}

export default TrialCard
