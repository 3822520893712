import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { formatDate, formatUser } from './NoteFunctions'
import { useGlobalContext } from '../../LoadConfig'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosResponse } from 'axios/index'
import NoteService from '../../../APIs/MyApi_Note'
import { useAuth0 } from '@auth0/auth0-react'
import { usePatientContext } from '../../PatientLoad'
import usePatientId from '../../../Hooks/usePatientId'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { LoadingButton } from '@mui/lab'

function NoteDialogue({
  mode,
  note,
  setMode,
  setNote,
}: {
  mode: 'view' | 'add' | undefined
  note?: any
  setMode: any
  setNote: any
}) {
  const { setError } = useMessagePopupContext()
  const { getAccessTokenSilently } = useAuth0()
  const queryClient = useQueryClient()
  const { usersData } = useGlobalContext()
  const patientId = usePatientId()
  const [localNote, setLocalNote] = useState<any>({})

  useEffect(() => {
    if (mode === 'view') {
      setLocalNote(note)
    }
  }, [note, mode])

  const { criteriaData } = usePatientContext()

  const { mutate: addNote, isLoading } = useMutation<any, AxiosResponse>(
    () =>
      NoteService.addNote(getAccessTokenSilently(), criteriaData?.id!, {
        text: localNote.text.trim(),
      }),
    {
      onSuccess: () => {
        queryClient
          .invalidateQueries({
            queryKey: ['NoteService', 'getNotes', patientId],
          })
          .then(() => {
            setMode(undefined)
            setLocalNote({})
          })
      },
      onError: (err) => setError(err),
    },
  )

  return (
    <Dialog
      open={!!mode}
      onClose={() => {
        setMode(undefined)
        setLocalNote({})
      }}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 1000,
        },
      }}
    >
      <DialogTitle>{mode === 'add' ? 'Add Note' : 'View Note'}</DialogTitle>
      <DialogContent sx={{ minWidth: 800 }}>
        {mode === 'view' && (
          <Box sx={{ display: 'flex', mt: 1 }}>
            <TextField
              label={'Created By'}
              value={formatUser(usersData, localNote.created_by)}
              fullWidth
              disabled
              sx={{ mr: 1 }}
            />
            <TextField
              label={'Created At'}
              value={formatDate(localNote?.created_at)}
              fullWidth
              disabled
            />
          </Box>
        )}
        <TextField
          sx={{ mt: 1 }}
          label={'Note'}
          value={localNote?.text || ''}
          fullWidth
          multiline
          minRows={5}
          maxRows={10}
          disabled={mode === 'view'}
          onChange={(e) =>
            setLocalNote((n: any) => {
              return { ...n, text: e.target.value }
            })
          }
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        {mode === 'view' ?
          <Button
            onClick={() => {
              setMode(undefined)
              setLocalNote({})
            }}
            color="primary"
          >
            OK
          </Button>
        : <>
            <LoadingButton
              disabled={!localNote?.text || localNote.text.trim().length === 0}
              loading={isLoading}
              onClick={() => addNote()}
              variant={'contained'}
              color="success"
            >
              Add
            </LoadingButton>
            <Button
              onClick={() => {
                setMode(undefined)
                setLocalNote({})
              }}
              color="error"
              variant={'outlined'}
            >
              Cancel
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  )
}

export default NoteDialogue
