import React, { useMemo, useState } from 'react'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { Box, Paper, TableContainer } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AuditLogEntry, Note } from '../../../typescript/API'
import { AxiosResponse } from 'axios'
import NoteService from '../../../APIs/MyApi_Note'
import usePatientId from '../../../Hooks/usePatientId'
import { usePatientContext } from '../../PatientLoad'
import { useAuth0 } from '@auth0/auth0-react'
import { useMessagePopupContext } from '../../../garvan-react/Components/ModalFeedback/MessagePopup'
import { useGlobalContext } from '../../LoadConfig'
import { datetimeFormat } from '../../../functions/FormatValues'
import NoteToolbar from './NoteToolbar'
import NoteDialogue from './NoteDialogue'
import { formatUser } from './NoteFunctions'
import AuditApiService from '../../../APIs/AuditAPI'
import { interpretAuditChange } from './InterpretAuditEntries'

function Notes() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  const { setError } = useMessagePopupContext()
  const patientId = usePatientId()
  const { usersData } = useGlobalContext()
  const [mode, setMode] = useState<'add' | 'view' | undefined>(undefined)
  const [selectedRow, setSelectedRow] = useState<null | any>(null)

  const { criteriaData, rankData } = usePatientContext()

  const noteQuery = useQuery<Note[], AxiosResponse>(
    ['NoteService', 'getNotes', patientId],
    () => NoteService.getNotes(getAccessTokenSilently(), criteriaData?.id!),
    {
      enabled: isAuthenticated && !!criteriaData?.id,
      onError: (err) => setError(err),
    },
  )

  const rankedAuditQuery = useQuery<AuditLogEntry[], AxiosResponse>(
    ['AuditApiService', 'getAuditLogs', 'rankedlist', patientId],
    () =>
      AuditApiService.getAuditLogs(
        getAccessTokenSilently(),
        'rankedlist',
        patientId,
      ),
    {
      enabled: isAuthenticated && !!rankData?.rank_id,
      onError: (err) => setError(err),
    },
  )
  const criteriaAuditQuery = useQuery<AuditLogEntry[], AxiosResponse>(
    ['AuditApiService', 'getAuditLogs', 'criteria', patientId],
    () =>
      AuditApiService.getAuditLogs(
        getAccessTokenSilently(),
        'criteria',
        patientId,
      ),
    {
      enabled: isAuthenticated && !!criteriaData?.id,
      onError: (err) => setError(err),
    },
  )

  const conformedRankedLogs: Partial<Note>[] = useMemo(() => {
    if (rankedAuditQuery.data) {
      return rankedAuditQuery.data.map((audit) => {
        return {
          text: interpretAuditChange(audit.changes),
          created_by: audit.user_id,
          created_at: audit.timestamp,
          ...audit,
        }
      })
    }
    return []
  }, [rankedAuditQuery.data])
  const conformedCriteriaLogs: Partial<Note>[] = useMemo(() => {
    if (criteriaAuditQuery.data) {
      return criteriaAuditQuery.data
        .filter((audit) => {
          const auditKeys = Object.keys(JSON.parse(audit.changes))
          const excludePatient =
            auditKeys.length === 1 && auditKeys[0] === 'patient'
          return !excludePatient
        })
        .map((audit) => {
          return {
            text: interpretAuditChange(audit.changes),
            created_by: audit.user_id,
            created_at: audit.timestamp,
            ...audit,
          }
        })
    }
    return []
  }, [criteriaAuditQuery.data])

  const notesCols: GridColDef[] = [
    {
      headerName: 'Note',
      field: 'text',
      editable: false,
      renderCell: ({ value }) =>
        value ?
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {value.split(/\r?\n/).map((line: string) => (
              <span>{line}</span>
            ))}
          </Box>
        : <span />,
      flex: 5,
    },
    {
      headerName: 'Author',
      field: 'created_by',
      editable: false,
      valueFormatter: (value) => formatUser(usersData, value),
      flex: 1,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      editable: false,
      valueFormatter: (value) => datetimeFormat(value),
      flex: 1,
    },
  ]

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: 1,
          mt: 1,
          height: '35vh',
          '& .datagrid--Audit': {
            backgroundColor: 'lightgoldenrodyellow',
          },
        }}
      >
        <DataGridPro
          sx={{
            '& .MuiDataGrid-cell': {
              alignContent: 'center',
            },
          }}
          hideFooter
          columns={notesCols}
          loading={
            noteQuery.isLoading ||
            rankedAuditQuery.isLoading ||
            criteriaAuditQuery.isLoading
          }
          rows={[
            ...(noteQuery.data ?? []),
            ...conformedRankedLogs,
            ...conformedCriteriaLogs,
          ]}
          getRowHeight={() => 'auto'}
          disableColumnMenu
          onRowClick={(r) => {
            setSelectedRow(r.row)
            setMode('view')
          }}
          getRowClassName={(params) => {
            return params.row?.changes ? '' : 'datagrid--Audit'
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {
                criteria: false,
              },
            },
          }}
          slots={{
            toolbar: NoteToolbar as any,
          }}
          slotProps={{
            toolbar: {
              title: 'Notes & Changes',
              setMode: setMode,
            },
          }}
        />
      </TableContainer>
      <NoteDialogue
        mode={mode}
        note={selectedRow}
        setMode={setMode}
        setNote={setSelectedRow}
      />
    </>
  )
}

export default Notes
