import {
  Autocomplete,
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React from 'react'
import {
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { usePatientContext } from '../../PatientLoad'
import { useGlobalContext } from '../../LoadConfig'
import { datetimeFormat } from '../../../functions/FormatValues'

function UserFormatter(props: GridRenderCellParams) {
  // FIXME - it seems completely unnecessary to create a formatter to do this. But I can't find another way
  const { row } = props
  const { usersData } = useGlobalContext()
  let user = usersData ? usersData.find((user) => user.id === row.author) : null
  if (user === undefined) {
    return <span />
  }
  return (
    <span>
      {user?.first_name !== '' || user?.last_name !== '' ?
        user?.first_name + ' ' + user?.last_name
      : user?.username}
    </span>
  )
}

function CustomEditComponent(props: GridRenderEditCellParams) {
  const { id, value, field, options } = props
  const apiRef = useGridApiContext()
  const { molArchive } = usePatientContext()
  const { usersData, cannedComments, organisations } = useGlobalContext()

  const locations =
    molArchive?.comments?.location !== undefined ?
      molArchive.comments.location.map((item) => (
        <MenuItem
          value={item}
          key={item}
        >
          {item}
        </MenuItem>
      ))
    : []
  const styles =
    molArchive?.comments?.style !== undefined ?
      molArchive.comments.style.map((item) => (
        <MenuItem
          value={item}
          key={item}
        >
          {item}
        </MenuItem>
      ))
    : []
  const usersList =
    usersData ?
      usersData.map((user) => (
        <MenuItem
          value={user.id}
          key={user.id}
        >
          {user.first_name !== '' || user.last_name !== '' ?
            user.first_name + ' ' + user.last_name
          : user.username}
        </MenuItem>
      ))
    : []

  //items must be sorted by groupBy property (org)
  let cannedOptions =
    cannedComments && organisations ?
      cannedComments
        .map((option) => {
          //get org label
          const orgLabel = organisations.filter(
            (org) => org.id === option.org,
          )?.[0]?.name
          return { label: option.subject, id: option.comment, org: orgLabel }
        })
        .sort((a, b) => {
          return a.label < b.label ? 1 : -1
        })
        .sort((a, b) => {
          return a.org < b.org ? 1 : -1
        })
    : []

  if (['locations', 'styles', 'users'].includes(options)) {
    let opts: any[] = []
    if (options === 'locations') {
      opts = locations
    } else if (options === 'styles') {
      opts = styles
    } else if (options === 'users') {
      opts = usersList
    }
    return (
      <Box sx={{ px: 1 / 3, width: 1 }}>
        <Select
          fullWidth
          value={value}
          onChange={(e) =>
            apiRef.current.setEditCellValue({
              id,
              field,
              value: e.target.value,
            })
          }
        >
          {opts}
        </Select>
      </Box>
    )
  } else if (options === 'canned') {
    return (
      <Autocomplete
        fullWidth
        freeSolo
        value={value || ''}
        onChange={(event: any, newValue) => {
          //Triggered on canned list select
          apiRef.current.setEditCellValue({ id, field, value: newValue.id })
        }}
        onInputChange={(event, newInputValue) => {
          //Triggered on user type
          apiRef.current.setEditCellValue({ id, field, value: newInputValue })
        }}
        disableClearable
        options={cannedOptions}
        groupBy={(option) => option.org}
        renderInput={(params) => (
          <Box sx={{ px: 1 / 3, width: 1 }}>
            <TextField
              multiline //causes console warning -> ignore
              rows={10}
              margin="dense"
              {...params}
              inputProps={{
                ...params.inputProps,
                spellCheck: true, //GCMP-623
              }}
              error={
                props?.value?.length !== undefined && props?.value?.length < 1
              }
            />

            {props?.value?.length < 1 && (
              <FormHelperText error>
                You cannot add an empty comment!
              </FormHelperText>
            )}
          </Box>
        )}
      />
    )
  } else {
    return (
      <Box sx={{ px: 1 / 3, width: 1 }}>
        <TextField
          sx={{ mx: 1 }}
          fullWidth
          value={value}
          onChange={(e) =>
            apiRef.current.setEditCellValue({
              id,
              field,
              value: e.target.value,
            })
          }
          error={value?.length !== undefined && value?.length < 1}
        />
        {value?.length < 1 && (
          <FormHelperText error>
            You must provide a comment 'subject'!
          </FormHelperText>
        )}
      </Box>
    )
  }
}

export const customTextCols: GridColDef[] = [
  {
    headerName: 'Comment',
    field: 'comment',
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent
        {...params}
        options={'canned'}
      />
    ),
    flex: 5,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return { ...params.props, error: !params.props.value }
    },
  },
  {
    headerName: 'Author',
    field: 'author',
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent
        {...params}
        options={'users'}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <UserFormatter
        {...params}
        row={params.row}
      />
    ),
    flex: 1,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return { ...params.props, error: !params.props.value }
    },
  },
  {
    headerName: 'Location',
    field: 'location',
    editable: true,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent
        {...params}
        options={'locations'}
      />
    ),
    flex: 1,
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      return { ...params.props, error: !params.props.value }
    },
  },
  {
    headerName: 'Criteria',
    field: 'criteria',
  },
  {
    headerName: 'ID',
    field: 'id',
    flex: 1 / 2,
    editable: false,
    renderEditCell: (params: GridRenderEditCellParams) => (
      <CustomEditComponent {...params} />
    ),
  },
  {
    headerName: 'Created',
    field: 'created_at',
    valueFormatter: (value) => datetimeFormat(value),
    flex: 1,
  },
]
